<template>
  <div>
    <div v-if="!loading">
      <FormErrorAlert
        v-if="errors.length > 0"
        :errors="errors"
        :formRef="this.$refs.operationalSeasonForm"
      />
      <ValidationObserver ref="operationalSeasonForm">
        <form @submit.prevent="addSection">
          <div class="row mb-3">
            <div class="col-md-3 col-sm-12" v-if="season.id">
              <TextInput
                name="Season Id"
                id="seasonId"
                v-model="season.seasonId"
                :disabled="true"
              />
            </div>
            <div :class="season.id ? 'col-md-9 col-sm-12' : 'col-12'">
              <TextInput
                name="Name"
                id="name"
                placeholder="Season name..."
                rules="required|max:50"
                v-model="season.name"
                :disabled="viewOnly"
              />
            </div>
          </div>
          <div class="row mb-2" v-if="!viewOnly">
            <div class="col-md-2 col-sm-12">
              <ValidationProvider
                rules="required"
                name="Season Type"
                v-slot="{ errors, ariaInput, ariaMsg }"
              >
                <label
                  @click="$refs.select.focus()"
                  :class="{ error: errors[0] }"
                  for="operationalSeasonType"
                >
                  Season Type
                  <span class="error">*</span>
                </label>
                <select
                  class="form-control form-control-lg"
                  id="operationalSeasonType"
                  v-model="segment.operationalSeasonTypeId"
                  v-bind="ariaInput"
                >
                  <option
                    v-for="type in seasonTypes"
                    :key="type.id"
                    :value="type.id"
                    aria-placeholder="Select Season Type..."
                    >{{ type.name }}</option
                  >
                </select>
                <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                  <li
                    v-for="(error, index) in errors"
                    :key="index"
                    class="error"
                    v-bind="ariaMsg"
                  >
                    {{ error }}
                  </li>
                </ul>
              </ValidationProvider>
            </div>
            <div class="col-md-3 col-sm-12">
              <DateInput
                vid="startDate"
                rules="isMonthDay|required"
                name="Start Date"
                id="startDate"
                v-model="segment.startDate"
                :initialDate="initialStartDate"
                format="MM/DD"
                placeholder="MM/DD"
                mask="##/##"
              />
            </div>
            <div class="col-md-3 col-sm-12">
              <DateInput
                vid="endDate"
                rules="isMonthDay|required"
                name="End Date"
                id="endDate"
                v-model="segment.endDate"
                :initialDate="initialEndDate"
                format="MM/DD"
                placeholder="MM/DD"
                mask="##/##"
              />
            </div>
            <div class="col-md-2 col-sm-12 mt-3">
              <label class="mt-4 season-repeated-font">
                <input
                  type="checkbox"
                  :checked="true"
                  disabled
                  id="seasonRepeated"
                />
                Repeat season every year
              </label>
            </div>
            <div class="col-md-2 col-sm-12 mt-3">
              <button
                class="btn btn-primary float-right mt-4"
                type="submit"
                :disabled="addRowDisabled"
              >
                + {{ this.editingSegment ? "EDIT" : "ADD" }} ROW
              </button>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <div class="card-title mb-0">Season Segments</div>
            </div>
            <div class="card-body">
              <b-table
                ref="table"
                striped
                :fields="seasonFields"
                :items="season.segments"
                stacked="md"
                small
                bordered
                sort-icon-left
              >
                <template v-slot:cell(operationalSeasonTypeId)="data">{{
                  getTypeName(data.item.operationalSeasonTypeId)
                }}</template>
                <template v-slot:cell(index)="data">
                  <button
                    class="btn btn-primary btn-sm mr-2"
                    type="button"
                    @click="editSection(data.index)"
                  >
                    Modify
                  </button>
                  <button
                    class="btn btn-primary btn-sm mr-1"
                    type="button"
                    @click="deleteSection(data.index)"
                  >
                    Delete
                  </button>
                </template>
              </b-table>
            </div>
          </div>
          <div role="tablist">
            <div class="card accordion">
              <div
                class="card-header accordion-header"
                role="tab"
                v-b-toggle.calendar-view
              >
                <div class="card-title mb-0">
                  Calendar View
                  <div class="float-right">
                    <i class="fas fa-chevron-up" v-if="showCalendar"></i>
                    <i class="fas fa-chevron-down" v-if="!showCalendar"></i>
                  </div>
                </div>
              </div>
              <b-collapse
                id="calendar-view"
                accordion="calendar-view"
                role="tabpanel"
                v-model="showCalendar"
              >
                <div class="card-body">
                  <div class="row ml-2">
                    <div class="legend open"></div>
                    <span class="pt-1">Open</span>
                    <div class="legend closed"></div>
                    <span class="pt-1">Closed</span>
                    <div class="legend walkin"></div>
                    <span class="pt-1">Walk-in</span>
                  </div>
                  <div class="row" v-if="showCalendar">
                    <div
                      class="col-md-3 col-sm-12 px-4 py-4"
                      v-for="(cal, index) in calendars"
                      :key="index"
                    >
                      <FullCalendar :options="cal" />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                class="btn btn-primary mr-2"
                type="button"
                @click="modify"
                v-show="viewOnly"
              >
                MODIFY
              </button>
              <button
                class="btn btn-primary mr-2"
                type="button"
                @click="save"
                :disabled="season.segments.length === 0"
                v-if="!viewOnly"
              >
                SAVE
              </button>
              <button
                class="btn btn-cancel mr-2"
                type="button"
                @click="cancel"
                v-if="!seasonChanged"
              >
                CANCEL
              </button>
              <ConfirmationButton
                class="mr-2"
                buttonText="CANCEL"
                buttonClass="btn-secondary"
                confirmationText="There is some unsaved data, would you like to save it?"
                affirmativeButton="SAVE"
                negativeButton="CANCEL"
                @affirmative="save"
                @negative="cancel"
                v-if="seasonChanged"
              />
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import ConfirmationButton from "@/components/layout/ConfirmationButton.vue";
import AdminTenantService from "@/services/admin/AdminTenantService.js";
import TextInput from "@/validation/TextInput.vue";
import DateInput from "@/validation/DateInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import moment from "moment";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  name: "AdminOperationalSeason",
  components: {
    TextInput,
    DateInput,
    ConfirmationButton,
    FormErrorAlert,
    ValidationObserver,
    ValidationProvider,
    FullCalendar
  },
  props: {
    operationalSeason: {
      type: Object,
      default: null
    },
    viewOnlyMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      showCalendar: false,
      seasonTypes: [],
      season: this.operationalSeason,
      initialSeason: {
        ...this.operationalSeason,
        segments: [...this.operationalSeason.segments]
      },
      viewOnly: this.viewOnlyMode,
      segment: {
        operationalSeasonTypeId: "",
        startDate: "",
        endDate: ""
      },
      editingSegment: false,
      events: [],
      errors: [],
      seasonFields: [
        {
          key: "operationalSeasonTypeId",
          sortable: true,
          label: "Season Type",
          class: "seasonTypeColumn"
        },
        { key: "startDate", sortable: true },
        { key: "endDate", sortable: true }
      ]
    };
  },
  methods: {
    cancel() {
      this.clearSection();
      this.$emit("cancel");
    },
    viewCancel() {
      this.selectedSeason = null;
    },
    modify() {
      this.viewOnly = false;
      this.checkViewOnly();
    },
    checkViewOnly() {
      if (!this.viewOnly) {
        this.seasonFields.push({ key: "index", label: "Actions/Options" });
      }
    },

    addSection() {
      this.$refs.operationalSeasonForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(
              this.$refs.operationalSeasonForm.errors
            )
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.operationalSeasonForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];

          const seasonSegment = {
            tenantId: this.tenantId,
            operationalSeasonId: this.season.Id,
            operationalSeasonTypeId: this.segment.operationalSeasonTypeId,
            startDate: this.segment.startDate,
            endDate: this.segment.endDate
          };
          this.season.segments.push(seasonSegment);
          this.sortSegments();
          this.setEvents();
          this.clearSection();
          this.$refs.operationalSeasonForm.reset();
          this.editingSegment = false;
        }
      });
    },
    getTypeName(id) {
      const type = this.seasonTypes.find(x => x.id === id);
      return type?.name;
    },
    clearSection() {
      this.segment = {
        operationalSeasonTypeId: "",
        startDate: "",
        endDate: ""
      };
      if (this.season.segments.length > 0) {
        const endDate = this.season.segments[this.season.segments.length - 1]
          .endDate;
        const now = new Date();
        this.$set(
          this.segment,
          "startDate",
          moment(endDate + "/" + now.getFullYear(), "MM-DD-YYYY")
            .add(1, "days")
            .format("MM/DD/YYYY")
        );
      }
    },
    async save() {
      this.loading = true;
      if (this.validateSections()) {
        const service = new AdminTenantService(this.tenantId);
        const response = await service.saveOperationalSeason(this.season);
        if (response?.statusCode === "Success") {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Operation Season have been saved.",
            layer: "admin"
          });
          this.loading = false;
          this.cancel();
        } else {
          this.loading = false;
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      } else {
        this.loading = false;
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message:
            "All segments must be consecutive and Seasons must start on Januray 1st and end on December 31st",
          layer: "admin"
        });
      }
    },
    sortSegments() {
      this.season.segments.sort((a, b) => {
        let now = new Date();
        return moment(a.startDate + "/" + now.getFullYear()).isSameOrAfter(
          b.startDate + "/" + now.getFullYear(),
          "day"
        )
          ? 1
          : -1;
      });
    },
    editSection(index) {
      if (this.editingSegment) {
        this.season.segments.push(this.segment);
      }
      this.segment = { ...this.season.segments.splice(index, 1)[0] };
      this.sortSegments();
      this.editingSegment = true;
    },
    deleteSection(index) {
      this.season.segments.splice(index, 1);
      this.setEvents();
    },
    setOrder() {
      this.season.segments.forEach((x, index) => (x.displayOrder = index + 1));
    },
    async getTypes() {
      this.loading = true;
      const service = new AdminTenantService(this.tenantId);
      const response = await service.getOperationalSeasonTypes();
      if (response?.statusCode === "Success") {
        this.seasonTypes = response.data;
        this.loading = false;
      } else {
        this.loading = false;
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    validateSections() {
      const first = this.season.segments[0];
      const last = this.season.segments[this.season.segments.length - 1];
      if (first?.startDate != "01/01") return false;
      if (last?.endDate != "12/31") return false;

      const now = new Date();
      let isValid = true;
      for (let i = 0; i < this.season.segments.length; i++) {
        if (i === this.season.segments.length - 1) {
          break;
        }
        let item = this.season.segments[i];
        let nextItem = this.season.segments[i + 1];
        let nextItemStart = moment(
          item.endDate + "/" + now.getFullYear(),
          "MM-DD-YYYY"
        )
          .add(1, "days")
          .format("MM/DD");
        if (nextItemStart != nextItem.startDate) {
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    setEvents() {
      this.events = [];
      const now = new Date();
      this.season.segments.forEach(x => {
        for (
          var d = moment(x.startDate + "/" + now.getFullYear()).toDate();
          d <= moment(x.endDate + "/" + now.getFullYear()).toDate();
          d.setDate(d.getDate() + 1)
        ) {
          let event = {
            start: moment(d).format("YYYY-MM-DD"),
            end: moment(d)
              .add(1, "days")
              .format("YYYY-MM-DD"),
            display: "background"
          };
          if (x.operationalSeasonTypeId === 1) {
            event.backgroundColor = "white";
            event.title = "O";
          } else if (x.operationalSeasonTypeId === 2) {
            event.backgroundColor = "grey";
            event.title = "C";
          } else {
            event.backgroundColor = "#1078A8";
            event.title = "W";
          }
          this.events.push(event);
        }
      });
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    addRowDisabled() {
      return (
        this.segment.operationalSeasonTypeId === "" ||
        this.segment.startDate === "" ||
        this.segment.endDate === ""
      );
    },
    initialEndDate() {
      if (this.segment.startDate) {
        return moment(this.segment.startDate, "MM-DD-YYYY")
          .add(1, "days")
          .format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    initialStartDate() {
      if (this.segment.startDate) {
        return moment(this.segment.startDate, "MM-DD-YYYY").format(
          "YYYY-MM-DD"
        );
      } else {
        return "";
      }
    },
    seasonChanged() {
      return JSON.stringify(this.season) !== JSON.stringify(this.initialSeason);
    },
    calendars() {
      const calendars = [];
      const now = new Date();
      for (let i = 0; i < 12; i++) {
        calendars.push({
          plugins: [dayGridPlugin, interactionPlugin],
          initialView: "dayGridMonth",
          initialDate: new Date(now.getFullYear(), i, 1),
          events: this.events,
          showNonCurrentDates: false,
          eventBorderColor: "grey",
          height: "400px",
          headerToolbar: {
            start: "title",
            center: "",
            end: ""
          },
          views: {
            dayGridMonth: {
              titleFormat: { month: "long" }
            }
          }
        });
      }
      return calendars;
    }
  },
  created() {
    this.getTypes();
    this.setEvents();
    this.checkViewOnly();
  }
};
</script>

<style scoped>
.type-label {
  font-weight: bolder;
}
.buttons {
  margin-left: auto;
}
.delete {
  color: red;
  order: 2;
}
.pointer {
  cursor: pointer;
}
.section-item {
  height: 67px;
}
.legend {
  float: left;
  width: 20px;
  height: 20px;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  opacity: 0.3;
}

.open {
  background: white;
}

.closed {
  background: grey;
}

.walkin {
  background: #1078a8;
}
.fc-event,
.fc-event-dot {
  border-color: 1px solid grey !important;
}
.accordion-header {
  cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.calendar-container {
  height: 1000px;
}
.season-repeated-font {
  font-size: small;
}
</style>
