<template>
  <div>
    <h3 class="pb-2">
      Operational Season Management
      <hr />
    </h3>
    <div v-if="!loading">
      <div v-if="!addNewSeason && !selectedSeason">
        <div class="row">
          <div class="col-12">
            <button
              class="btn btn-primary float-left mb-3"
              type="button"
              @click="addNewSeason = true"
            >
              ADD NEW SEASON
              <i class="fa fa-plus-circle" />
            </button>
          </div>
        </div>
        <div>
          <div class="card">
            <div class="card-header">
              <div class="card-title mb-0">Seasons</div>
            </div>
            <div class="card-body">
              <b-table
                ref="table"
                striped
                :fields="fields"
                :items="seasons"
                stacked="md"
                small
                class="mt-4"
                bordered
                sort-icon-left
              >
                <template v-slot:cell(dateLastModified)="data">{{
                  data.item.dateLastModified | formatDateTime
                }}</template>
                <template v-slot:cell(id)="data">
                  <button
                    class="btn btn-primary btn-sm mr-2"
                    @click="viewSeason(data.item.id)"
                  >
                    View
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="editSeason(data.item.id)"
                  >
                    Modify
                  </button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <AdminOperationalSeason
        :operationalSeason="newSeason"
        @cancel="cancelAddNew"
        v-if="addNewSeason"
      />
      <AdminOperationalSeason
        :operationalSeason="selectedSeason"
        :viewOnlyMode="viewOnly"
        @cancel="cancelSelectedSeason"
        v-if="selectedSeason"
      />
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import AdminOperationalSeason from "@/components/admin/AdminOperationalSeason.vue";
import AdminTenantService from "@/services/admin/AdminTenantService.js";

export default {
  name: "AdminOperationalSeasonManagement",
  title: "Admin - Operational Season Management",
  components: { AdminOperationalSeason },
  data() {
    return {
      loading: false,
      selectedSeason: null,
      seasons: [],
      addNewSeason: false,
      viewOnly: true,
      newSeason: {
        name: "",
        segments: []
      },
      fields: [
        { key: "seasonId", sortable: true },
        { key: "name", sortable: true },
        { key: "dateLastModified", sortable: true, class: "column" },
        { key: "id", label: "Actions/Options", class: "column" }
      ]
    };
  },
  methods: {
    cancelAddNew() {
      this.getSeasons();
      this.newSeason = {
        name: "",
        segments: []
      };
      this.addNewSeason = false;
    },
    cancelSelectedSeason() {
      this.getSeasons();
      this.selectedSeason = null;
    },
    async getSeasons() {
      this.loading = true;
      const service = new AdminTenantService(this.tenantId);
      const response = await service.getOperationalSeasons();
      if (response?.statusCode === "Success") {
        response.data.forEach(x => {
          x.seasonId = "SEASON - " + x.id;
        });
        this.seasons = response.data;
        this.loading = false;
      } else {
        this.loading = false;
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    viewSeason(id) {
      const season = this.seasons.find(x => x.id === id);
      this.selectedSeason = season;
      this.viewOnly = true;
    },
    editSeason(id) {
      const season = this.seasons.find(x => x.id === id);
      this.selectedSeason = season;
      this.viewOnly = false;
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  created() {
    this.getSeasons();
  }
};
</script>

<style scoped>
.column {
  width: 200px;
}
</style>
